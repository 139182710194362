.oatall-input-switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 22px;
}

.oatall-input-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
