.resizer {
  position: absolute;
  border-radius: 50%;
}

.resizer.top-left {
  left: -16px;
  top: -16px;
}

.resizer.top-right {
  right: -16px;
  top: -16px;
}

.resizer.bottom-left {
  left: -16px;
  bottom: -16px;
}

.resizer.bottom-right {
  right: -16px;
  bottom: -16px;
}

@media only screen and (min-width: 850px) {
  .resizer.top-left {
    left: -5px;
    top: -5px;
  }

  .resizer.top-right {
    right: -5px;
    top: -5px;
  }

  .resizer.bottom-left {
    left: -5px;
    bottom: -5px;
  }

  .resizer.bottom-right {
    right: -5px;
    bottom: -5px;
  }
}
