/* @import url("https://fonts.googleapis.com/css2?family=Padauk&family=Marko+One&family=Mukta+Mahee:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comic+Neue&family=Open+Sans&family=Comic+Neue:ital@1&family=Do+Hyeon&family=Merriweather&family=Roboto+Condensed&family=Poppins&family=Titillium+Web&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Poppins&family=Courgette&family=Josefin+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap"); */



h1,
h2,
h3,
h4,
h5,
p,
ul,
ol {
  margin: 0px;
}
